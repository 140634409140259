import axios from "axios"
import env from '@/environment';

const url = env.APISERVICE;

const delmondApi = axios.create({
    baseURL: `${url}/apiDelmond`
})


delmondApi.interceptors.request.use((config)=>{
config.headers.Authorization =`Bearer ${localStorage.getItem('idToken')}`
  
    return config
  })


export default delmondApi